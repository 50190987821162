<template>
  <div class="aboutus">
    <p class="comment">{{ $t("product2") }}<br /><br />{{ $t("product21") }}</p>
    <p class="auther">-Ju Mai Trading Co., Ltd</p>
    <div class="aboutus-ad">
      <div>
        <div class="icon">
          <span class="circle"><i class="iconfont icon-product"></i></span>
        </div>
        <p class="title">{{ $t("product3") }}</p>
        <p class="con">{{ $t("product4") }}</p>
      </div>
      <div>
        <div class="icon">
          <span class="circle"><i class="iconfont icon-yonghu"></i></span>
        </div>
        <p class="title">{{ $t("product5") }}</p>
        <p class="con">{{ $t("product6") }}</p>
      </div>
      <div>
        <div class="icon">
          <span class="circle"><i class="iconfont icon-fenxi"></i></span>
        </div>
        <p class="title">{{ $t("product7") }}</p>
        <p class="con">{{ $t("product8") }}</p>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped lang="less">
.aboutus {
  font-size: 24px;
  padding: 100px 100px;
  justify-content: center;
  align-items: center;
  .comment {
    max-width: 1000px;
    font-size: 30px;
    margin: auto;
    font-style: italic;
    margin-bottom: 20px;
  }
  .auther {
    text-align: right;
    font-size: 30px;
    max-width: 1000px;
    margin: 0 auto 80px;
  }
  .aboutus-ad {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 56px;
    grid-row-gap: 30px;
    font-size: 18px;
    > div {
      background-color: #2153d3;
      border-radius: 20px;
      color: #fff;
      padding: 28px;
    }
    .title {
      font-size: 40px;
      margin-bottom: 24px;
    }
    .icon {
      text-align: center;
      width: 120px;
      height: 120px;
      margin-bottom: 20px;
      text-align: center;
      background-color: #a8c0ea;
      color: #2153d3;
      border-radius: 50%;
      position: relative;

      .circle {
        width: 80px;
        height: 80px;
        background-color: #fff;
        border-radius: 50px;
        display: inline-block;
        margin: auto;
        position: relative;
        top: 20px;
        line-height: 80px;

        > i {
          font-size: 40px;
          z-index: 3;
          color: #2153d3;
        }
      }
    }
    .con {
      font-size: 26px;
    }
  }
}
</style>
