import { defineStore } from "pinia";
import { ref } from "vue";
import i18n from "@/locales";
type ILang = "en" | "zh-CN" | "zh-TW";

export const useLocaleStore = defineStore("locale", () => {
  const locale = ref(i18n.global.locale.value);
  // 设置locale
  function setLocale (lang: ILang) {
    locale.value = lang;
    i18n.global.locale.value = lang;
    localStorage.setItem('locale', lang)
  }

  return { locale, setLocale };
});
