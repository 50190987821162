// 跟.postcssrc.js里的rootValue的值是一致的
const baseSize = 100;
// 设置rem函数
function setRem () {
  // 当前页面宽度相对于1920宽的缩放比例，可根据自己需要修改。pc端一般使用的是1920，这里使用1920px
  const scale = document.documentElement.clientWidth / 1920;
  // 设置页面根节点字体大小，最高为两倍图，及设计稿为1920*2
  console.log(baseSize * Math.min(scale, 2), "8888");
  document.documentElement.style.fontSize =
    (baseSize * Math.min(scale, 2) > 12 ? baseSize * Math.min(scale, 2) : 12) +
    "px";
  console.log(document.documentElement.style.fontSize, "8888");
}
// 初始化
setRem();
// 改变窗口大小时重新设置rem
window.onresize = function () {
  setRem();
};
