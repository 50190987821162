<template>
  <div class="footer">
    <p class="flex"><img src="@/assets/logo2.png" class="logo" /> ShiftDock</p>
    <div>
      <p>©1Shift Corporation 2023.{{ $t("right") }}</p>
      <p>
        <a href="mailto:inquiry@shiftdock.xyz"
          ><i class="iconfont icon-youjian"></i>&nbsp; inquiry@shiftdock.xyz</a
        >&nbsp;&nbsp;
        <a><i class="iconfont icon-faceshu"></i>&nbsp;ShiftDock</a>
      </p>
    </div>
  </div>
</template>
<script lang="ts" setup></script>
<style lang="less">
.footer {
  height: 80px;
  padding: 20px 60px;
  background: #000;
  color: #fff;
  font-size: 24px;
  > div {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    font-size: 20px;
    p {
      display: flex;
      a {
        display: flex;
        align-content: center;
        text-decoration: none;
        color: #fff;
      }
    }

    .iconfont {
      font-size: 24px;
    }
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .logo {
    width: 36px;
    margin-right: 12px;
  }
}
</style>
