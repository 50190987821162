export default {
  home: "Home",
  services: "Services",
  m1: "Ship Internationally, Delivery locally",
  m2: "ShiftDock integrates freight capacity and local delivery APls to provide global door to door logistic experience.",
  bendi1: "Local delivery",
  bendi2: "Shift Dock One Stop Local Delivery Solution",
  bendi3:
    "Local delivery services cover China and Southeast Asian countries such as Thailand, Malaysia, Singapore, the Philippines, Indonesia, Vietnam, Cambodia, etc. By combining with leading local delivery platforms, we provide you with an easy and fast shipping experience.",
  haiyun1: "Sea Transportation",
  haiyun2: "You fill the container, we fill the others  ",
  haiyun3:
    "We provide you with real-time services - comprehensive search of the location of goods, shipping nodes, and estimated arrival time, allowing you to easily control the overall situation. With advanced technology and a global logistics network, we provide you with flexible sea freight full container logistics solutions to help you fulfill your promises to customers.  ",
  kongyun1: "Air Transport",
  kongyun2:
    "Ultimate air freight, ensuring peace of mind and peace of mind for you",
  kongyun3:
    "We offer a range of air freight solutions, from emergency and next flight services to 1-5 business days of point-to-point service. We offer you reliable air freight options, allowing you to transport your goods to the places you need according to your budget when needed, all over the world.",
  kache1: "Truckage",
  kache2: "Accelerate your logistics direct train",
  kache3:
    "Based on carefully selected operators and high-quality hub locations, our truck transportation solutions have a wider coverage range and better flexibility. In addition to cost-effective economic services, we also offer customized freight solutions. ",
  qingguan1: "Customs /Clearance",
  qingguan2: "Upgrade your customs clearance experience  ",
  qingguan3:
    " Even if you do not ship through ShiftDock, you can still cooperate with our customs clearance agent to simplify the process and save costs. Our digital platform can maximize the utilization of customs data to provide analytical value for your other businesses.",
  serviced1: "Outsourced procurement services",
  serviced2:
    "ShiftDock enables seamless cooperation between you and your suppliers on the Quda platform. From procurement to travel orders to final delivery, the platform's order management system unlocks comprehensive visibility and control for you.",
  servicet1: "Tax Refund",
  servicet2:
    "Our customs clearance experts can also provide you with necessary tax refund services through comprehensive supervision, including internal control and tax refund declaration (for certain taxes), tax refund preparation, analysis, submission to customs departments, and assistance in the audit process.  ",
  advantages1: "Advantages",
  advantages2: "Transparent Pricing, Smart Pricing",
  advantages3:
    " Ensures your shipment will arrive by the pre-determined time of your choice on the standard-service delivery day",
  product1: "When you know us, you love us",
  product2:
    " “Fair pricing, attentive and high-quality service, and most importantly, timely, considerate and polite service to the consignee. The goods can be handed over to them with confidence, and there is no need to worry about complaints from the consignee .",
  product21:
    "The most impressive thing is that the colleagues responsible for communication always answer questions, are serious and responsible, and respond promptly in case of unexpected situations late at night. This is very appreciated.”",

  product3: "Products and Services",
  product4:
    "We provide comprehensive services, including but not limited to account opening, transaction security, and customer support. Our service process fully considers the convenience of customers and aims to provide a simple, fast, and efficient service experience.",
  product5: "User Value",
  product6:
    "We are committed to providing personalized services to meet their needs. We provide detailed operation guidelines and are equipped with a professional customer service team to provide one-on-one answers and assistance, ensuring that every customer can be satisfied with using our products  ",
  product7: "Comparative analysis of competitors",
  product8:
    "Our account opening process is designed to be simple and clear, and can be completed without the need for complex steps. Secondly, we have a strong technical team that can ensure account security and optimize user experience",
  haipai: "Sea Freight Express",
  kongpai: "Air Freight Express",
  haika: "SeaCard Express Service",
  tiepai: "Iron Card Express Service",
  kuaidi: "Express Line",
  zhenggui: "Full Container Load (FCL) Service",
  xiaobao: "Parcel Express Service",
  qita: "Other Specialized lines ",
  departure: "Departure",
  destination: "Destination",
  weight: "Weight",
  volume: "Volume",
  properties: "Properties",
  right: "All right reserved",
};
