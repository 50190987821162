export default {
  home: "首页",
  services: "服務",
  m1: "跨國航運，本地速遞。",
  m2: "ShiftDock整合貨運能力和本地配送APL，致力於打造高效服務的全球門到門物流體驗。",
  bendi1: "本地配送",
  bendi2: "ShiftDock 一站式本地配送解决方案",
  bendi3:
    "本地配送服務覆蓋中國及東南亞國家——泰國、馬來西亞、新加坡、菲律賓、印尼、越南、高棉等，通過與本地領先配送平臺的結合，為您提供輕鬆快捷的運送體驗。",
  haiyun1: "海運",
  haiyun2: "你裝滿集裝箱，其他的交給我們",
  haiyun3:
    "為你提供即時服務——全方位查找貨物所在點、出運節點、及預計到達時間，讓你輕鬆掌控全域。 憑藉先進的技術與覆蓋全球的物流網路，為你提供靈活的海運品相整箱物流解決方案，助你兌現對客戶的承諾。",
  kongyun1: "空運",
  kongyun2: "極致空運，讓您安心無憂 ",
  kongyun3:
    "提供一系列航空貨運解決方案，從緊急的、下一班機服務到1-5個工作日的點到點服務，我們為您提供值得信賴的空運選項，讓您在需要時按您的預算運輸貨物到您需要的地方，遍及全球。",
  kache1: "卡車運輸",
  kache2: "迅速物流，高效達",
  kache3:
    "基於精心挑選的運營商和處於優質位置的樞紐點，我們的卡車運輸解決方案具備更廣泛的覆蓋範圍及更好的靈活性。 除了具有成本效益的經濟服務外，我們還提供定制型貨運解決方案。  ",
  qingguan1: "清關/報關",
  qingguan2: "報關升級，高效體驗",
  qingguan3:
    " 即便您不通過ShiftDock時赴達發貨，您也可以與我們的報關代理合作，將流程化繁為簡，節約成本。 我們的數位化平臺可以最大化地利用海關數據為您的其他業務提供分析價值。",
  serviced1: "代采服務",
  serviced2:
    "ShiftDock時赴達平臺讓您與您的供應商實現無縫合作。 從採購至旅行訂單直至最終交付，平臺訂單管理系統為您解鎖全方位可見性與掌控力。",
  servicet1: "退稅服務  ",
  servicet2:
    "我們的報關專家團隊提供全面監管下的報關服務，同時包括內部控制和特定稅種的稅務退款申報。 我們致力於為您提供高效、精確的稅務解決方案，包括稅款準備、分析、提交給海關當局，並在審計過程中提供協助。",
  advantages1: "優點",
  advantages2:
    "以透明和智慧定價為重點，注重價格明晰和數據驅動。 通過簡明的定價結構和公開資訊，滿足客戶需求。 利用先進數據分析和即時調整，確保持續競爭力。",
  advantages3: "我們承諾，貨物將在標準服務交付日期內準時抵達。",
  product1: "當你瞭解我們，你就愛我們",
  product2:
    " “價格公道、服務細心優質，最重要是對收貨人的服務準時、體貼和禮貌。貨物可以放心交給他們，不再擔心收貨人投訴。",
  product21:
    "印象最深刻的是負責對接的同事有問必答，認真負責任，深夜有突發情況也及時回覆，這個是非常欣賞的。”",
  product3: "產品服務",
  product4:
    "我們提供了全方位的服務，包括但不限於帳戶開設、交易安全、客戶支援，我們的服務流程充分考慮了客戶的便利性，旨在提供簡單、快速且高效的服務體驗。",
  product5: "用户体验",
  product6:
    "我們致力於提供個人化服務，以滿足客戶的獨特需求。 我們提供詳細的操作說明，並設有專業的客戶服務團隊，為每位客戶提供一對一的解答和支援，以確保他們能夠充分滿足並愉快地使用我們的產品。  ",
  product7: "競品分析",
  product8:
    "我們的賬戶開通流程經過精心設計，簡單明確，無需繁複步驟。 更重要的是，我們擁有一支強大的技術團隊，致力於保障帳戶的安全性，以及不斷優化用戶體驗，為你提供更可靠的服務。 ",
  haipai: "海派專線 ",
  kongpai: "空派專線",
  haika: "海卡專線",
  tiepai: "鐵派專線",
  kuaidi: "快遞專線",
  zhenggui: "整櫃專線",
  xiaobao: "小包專線",
  qita: "其他專線 ",
  departure: "发货地",
  destination: "目的地",
  weight: "重量",
  volume: "體積",
  properties: "内容",
  right: "保留所有權利",
};
