import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import "@/assets/font/iconfont.css";
import "@/utils/rem";
import i18n from "@/locales";
import pinia from '@/store'
createApp(App).use(router).use(ElementPlus).use(i18n).use(pinia).mount("#app");
