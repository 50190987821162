<template>
  <router-view/>
</template>

<style lang="less">
*{
  padding: 0 ;
  margin: 0;
}
html, body {
    position: relative;
    height: 100%;
    scroll-behavior: smooth;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
