export default {
  home: "首页",
  services: "服务",
  m1: "跨国航运，本地速递。",
  m2: "ShiftDock整合货运能力和本地配送APL，致力于打造高效服务的全球门到门物流体验。。",
  bendi1: "本地配送",
  bendi2: "ShiftDock 一站式本地配送解决方案",
  bendi3:
    "本地配送服务覆盖中国及东南亚国家——泰国、马来西亚、新加坡、菲律宾、印度尼西亚、越南、柬埔寨等，通过与本地领先配送平台的结合，为你提供轻松快捷的运送体验。",
  haiyun1: "海运",
  haiyun2: "你装满集装箱，其他的交给我们",
  haiyun3:
    "为你提供实时服务——全方位查找货物所在点、出运节点、及预计到达时间，让你轻松掌控全局。凭借先进的技术与覆盖全球的物流网络，为你提供灵活的海运品相整箱物流解决方案，助你兑现对客户的承诺。",
  kongyun1: "空运",
  kongyun2: "极致空运，让你安心无忧",
  kongyun3:
    "提供一系列航空货运解决方案，从紧急的、下一航班服务到 1-5个工作日的点到点服务，我们为你提供值得信赖的空运选项，让你在需要时按你的预算运输货物到你需要的地方，遍及全球。",
  kache1: "卡车运输",
  kache2: "迅速物流，高效达",
  kache3:
    "基于精心挑选的运营商和处于优质位置的枢纽点,我们的卡车运输解决方案具备更广泛的覆盖范围及更好的灵活性。除了具有成本效益的经济服务外，我们还提供定制型货运解决方案。",
  qingguan1: "清关/报关",
  qingguan2: "报关升级，高效体验",
  qingguan3:
    " 即便你不通过ShiftDock时赴达发货，你也可以与我们的报关代理合作，将流程化繁为简，节约成本。我们的数字化平台可以最大化地利用海关数据为你的其他业务提供分析价值。",
  serviced1: "代采服务",
  serviced2:
    "ShiftDock时赴达平台让你与你的供应商实现无缝合作。从采购至旅行订单直至最终交付，平台订单管理系统为你解锁全方位可见性与掌控力。",
  servicet1: "退税服务",
  servicet2:
    "我们的报关专家团队提供全面监管下的报关服务，同时包括内部控制和特定税种的税务退款申报。我们致力于为您提供高效、精确的税务解决方案，包括税款准备、分析、提交给海关当局，并在审计过程中提供协助。",
  advantages1: "优势",
  advantages2:
    "以透明和智能定价为重点，注重价格明晰和数据驱动。通过简明的定价结构和公开信息，满足客户需求。利用先进数据分析和实时调整，确保持续竞争力。",
  advantages3: "我们承诺，货物将在标准服务交付日期内准时抵达。",
  product1: "当你了解我们，你就爱我们",
  product2:
    " “价格公道、服务细心优质，最重要是对收货人的服务准时、体贴和礼貌。 货物可以放心交给他们，不再担心收货人投诉。",
  product21:
    " 印象最深刻的是负责对接的同事有问必答，认真负责任，深夜有突发情况也及时回复，这个是非常欣赏的。”",
  product3: "产品服务",
  product4:
    "我们提供了全方位的服务，包括但不限于账户开设、交易安全、客户支持，我们的服务流程充分考虑了客户的便利性，旨在提供简单、快速且高效的服务体验。",
  product5: "用户体验",
  product6:
    "我们致力于提供个性化服务，以满足客户的独特需求。我们提供详细的操作说明，并设有专业的客户服务团队，为每位客户提供一对一的解答和支持，以确保他们能够充分满足并愉快地使用我们的产品。",
  product7: "竞品分析",
  product8:
    "我们的账户开通流程经过精心设计，简单明确，无需繁复步骤。更重要的是，我们拥有一支强大的技术团队，致力于保障账户的安全性，以及不断优化用户体验，为你提供更可靠的服务。",
  haipai: "海派专线",
  kongpai: "空派专线",
  haika: "海卡专线",
  tiepai: "铁派专线",
  kuaidi: "快递专线",
  zhenggui: "整柜专线",
  xiaobao: "小包专线",
  qita: "其他专线",
  departure: "发货地",
  destination: "目的地",
  weight: "重量",
  volume: "体积",
  properties: "属性",
  right: "保留所有权利",
};
