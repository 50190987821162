<template>
  <div id="services" class="searchTab">
    <el-tabs @tab-change="changeTab()">
      <el-tab-pane v-for="item in list" :key="item.label">
        <template #label
          ><i :class="'iconfont ' + item.icon"></i
          ><span>{{ t(item.label) }}</span></template
        >
        <el-form :inline="true" :label-position="'top'" size="large">
          <el-col :span="8"
            ><el-form-item :label="t('departure')">
              <el-input
                v-model="data.value1"
                :placeholder="t('departure')"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" class="destination">
            <el-form-item :label="t('destination')">
              <el-input
                v-model="data.value2"
                :placeholder="t('destination')"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="8">
            <el-form-item
              :label="t('weight') + '/' + t('volume') + '/' + t('properties')"
              class="weight"
            >
              <el-input
                v-model="data.value4"
                :placeholder="t('weight')"
              ></el-input>
              <el-input
                v-model="data.value5"
                :placeholder="t('volume')"
              ></el-input>
              <el-input
                v-model="data.value6"
                :placeholder="t('properties')"
              ></el-input> </el-form-item
          ></el-col>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script lang="ts" setup>
import { reactive } from "vue";
import { useI18n } from "vue-i18n";
const { t } = useI18n();
const list = [
  { label: "haipai", icon: "icon-haipai" },
  { label: "kongpai", icon: "icon-kongpai" },
  { label: "haika", icon: "icon-haika" },
  { label: "tiepai", icon: "icon-tiepai" },
  { label: "kuaidi", icon: "icon-kuaidi" },
  { label: "zhenggui", icon: "icon-zhenggui" },
  { label: "xiaobao", icon: "icon-xiaobao" },
  { label: "qita", icon: "icon-qita" },
];
const data = reactive({
  value1: "",
  value2: "",
  value3: "",
  value4: "",
  value5: "",
  value6: "",
});

const changeTab = () => {
  data.value1 = "";
  data.value2 = "";
  data.value3 = "";
  data.value4 = "";
  data.value5 = "";
  data.value6 = "";
};
</script>
<style scoped lang="less">
.weight {
  .el-input {
    width: 30%;
    margin-right: 2%;
  }
}
</style>
<style lang="less">
.searchTab {
  padding-top: 90px;
  .el-tabs {
    background: #fff;
    box-shadow: 0px 10px 32px 0px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    .el-tabs__header {
      background: #4d75dc;
      border-radius: 20px 20px 0px 0px;

      .el-tabs__item {
        color: #fff;
        font-size: 20px;
        padding: 40px 0px;
        word-wrap: break-word;
        width: 210px;
        text-align: left;
        white-space: normal;
        // word-break: break-all;
        i {
          font-size: 24px;
          margin-right: 8px;
          color: #fff;
        }
      }

      .el-tabs__item.is-top:nth-child(2) {
        padding-left: 20px;
      }
      .el-tabs__item.is-active {
        color: #0047ff;
        background: #fff;
        border-radius: 8px 8px 0px 0px;
        i {
          color: #0047ff;
        }
      }
      .el-tabs__item.is-top:last-child {
        padding-right: 20px;
      }
      .el-tabs__active-bar {
        height: 0;
      }
    }
    .el-tabs__content {
      .el-form {
        padding: 20px 40px;
        .el-form-item__label {
          font-weight: 600;
          font-size: 24px;
          color: #000;
        }
      }
    }
  }
}
</style>
